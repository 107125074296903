import axios from "axios";

export const updateDataset = (data, status, access_token) => {
    axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/datasets/`,
        JSON.stringify([{...data, status}]),
        {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${access_token}`,
            },
        }
    );
}

export const fetchMe = (setCredits, setEmail) => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/me`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('catalog_access_token')}`,
        },
    })
        .then((res) => {
            setCredits(res.data.credits);
            setEmail(res.data.email);
        });
}

export const fetchMessageThread = (datasetId, setMessages) => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/messages?dataset_id=${datasetId}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('catalog_access_token')}`,
        },
    })
        .then((res) => {
            setMessages(res.data);
        });
}