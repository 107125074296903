import React, { useState } from "react";
import MyDropzone from "../others/MyDropzone";
import { BsXLg } from "react-icons/bs";
import "../../css/UploadButton.css";

function MyModal(props: any) {
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#2f4a5b",
      }}
    >
      <h2 style={{ letterSpacing: "1px" }}>UPLOAD A NEW DATASET</h2>
      <p>
        Please upload your datasets selection here.
        <br />
        Our team will review it and you will be notified by email
        <br /> as soon as we update the catalog.
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#464DF214",
          borderRadius: "24px",
          border: "2px dashed rgba(70, 77, 242, 0.08)",
          marginTop: "20px",
        }}
      >
        <MyDropzone />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "24px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            alignSelf: "center",
            fontWeight: "500",
            fontSize: "16px",
            width: "450px",
            marginTop: "30px",
          }}
        >
          For further questions, please contact us at
        </div>
        <a
          href="mailto:catalog@deeplife.co"
          style={{
            color: "#464DF2",
            textDecoration: "underline",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          catalog@deeplife.co
        </a>
      </div>
    </div>
  );
}

const UploadButton = () => {
  const [showModal, setShowModal] = useState(false);

  function handleShowModal() {
    setShowModal(true);
  }

  return (
    <>
      <button
        className="upload-button"
        style={{
          // width: "147px",
          height: "40px",
          borderRadius: "20px",
          borderColor: "lightGrey",
          borderWidth: "1px",
          borderStyle: "solid",
          paddingLeft: '1rem',
          paddingRight: '1rem',
          cursor: "pointer",
          fontSize: "14px",
          fontWeight: "500",
        }}
        onClick={handleShowModal}
      >
        Upload datasets
      </button>
      {showModal && (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              position: "relative",
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "32px",
              borderWidth: "1px",
              boxShadow: "0px 0px 32px 0px #464DF23D",
              borderColor: "#F3F3FA",
            }}
          >
            <MyModal />
            <BsXLg
              style={{ position: "absolute", top: "20", right: "20" }}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UploadButton;