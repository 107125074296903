import React from 'react'

interface StatusRendererProps {
    value?: string | null
}

const StatusRenderer: React.FC<StatusRendererProps> = ({ value }) => {
    if (!value || value === '-' || value === '') return null

    let style: any = {
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        borderRadius: '9999px',
        fontSize: '0.75rem',
        lineHeight: '1rem',
        fontWeight: '650',
    }

    if (value === 'In progress') {
        style = {
            ...style,
            backgroundColor: '#e6e6f9',
            color: '#464df2',
        }
    } else if (value === 'Done') {
        style = {
            ...style,
            backgroundColor: '#e3f2ef',
            color: '#24de6c',
        }
    } else if (value === 'On hold') {
        style = {
            ...style,
            backgroundColor: '#e4e6ed',
            color: '#8b99a5',
        }
    } else if (value === 'Preselected') {
        style = {
            ...style,
            backgroundColor: '#FFF3E0',
            color: '#FF9800',
        }
    }

    return <span style={style}>{value}</span>
}

export default StatusRenderer
