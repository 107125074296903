import React, { useMemo, useState } from "react";
import { ColDef, ICellRendererParams, ValueGetterParams, ValueSetterParams } from "ag-grid-community";
import ncbirenderer from "./renderers/NcbiCellRenderer";
import pmidrenderer from "./renderers/PmidCellRenderer";
import pmcrenderer from "./renderers/PmcCellRenderer";
import doirenderer from "./renderers/DoiCellRenderer";
import urlrenderer from "./renderers/UrlRenderer";
import textPopOver from "./renderers/TextCellPopover";
import axios from "axios";
import StatusRenderer from "./renderers/StatusRenderer";

const useDefaultColDef = (): ColDef => {
  return useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      minWidth: 120,
      filter: true,
      sortable: true,
      floatingFilter: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      tooltipValueGetter: (params) => {
        return params.valueFormatted ? params.valueFormatted : params.value;
      },
    };
  }, []);
};

const CellRenderer: React.FC<ICellRendererParams> = (props) => {
  if (props.value !== undefined) {
    return <>{props.value}</>;
  } else {
    return (
      <img
        src="https://www.ag-grid.com/example-assets/loading.gif"
        alt="Loading"
      />
    );
  }
};

const gridConfig = {
  columnDefs: [
    //{ field: "id", hide: true},
    { field: "human_clinical", hide: true, filter: "agTextColumnFilter"},
    {
        headerName: "Selection",
        headerClass: "selectioncolumn",
        children: [
            {
                headerName: "",
                field: "selected",
                colId: "selected",
                sortable : false,
                floatingFilter: false,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                width: 50,
                pinned: "left",
                suppressMovable: true,
            },
            {
                headerName: "Status",
                field: "status",
                colId: "status",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                width: 120,
                pinned: "left",
                suppressMovable: true,
                cellRenderer: StatusRenderer,
            },
            {
                headerName: "Comment",
                colId: "comment",
                editable: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorPopup: true,
                cellEditorParams: {
                    cols: '50',
                    rows: '6',
                },
                valueGetter: (params: ValueGetterParams) => {
                        return params.data.comment;
                    },
                valueSetter: (params: ValueSetterParams) => {
                    if (params.data.comment === params.newValue) {
                        return false;
                    } else {
                        params.data.comment = params.newValue;
                        axios.put(
                            `${process.env.REACT_APP_BACKEND_URL}/datasets/`,
                            JSON.stringify([{...params.data, comment: params.newValue}]),
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${localStorage.getItem(
                                        "catalog_access_token"
                                    )}`,
                                },
                            }
                        );
                        return true;
                    }
                },
                filter: "agTextColumnFilter",
                floatingFilter: true,
                width: 120,
                pinned: "left",
                suppressMovable: true,
            },
        ],
    },
    {
        headerName: "Dataset Information",
        headerClass: "datasetcolumn",
        children: [
            {
                headerName: "Update Info",
                field: "update_info",
                colId: "update_info",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Organism",
                field: "organism_name",
                colId: "organism_name",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "# Runs",
                field: "number_runs",
                colId: "number_runs",
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                width: 100,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Processable",
                field: "processable",
                colId: "processable",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                width: 150,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Dataset ID",
                field: "dataset_id",
                colId: "dataset_id",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                width: 150,
                cellRenderer: ncbirenderer,
            },
            {
                headerName: "Title",
                field: "title",
                colId: "title",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "BioProject",
                field: "bioproject",
                colId: "bioproject",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: ncbirenderer,
            },
            {
                headerName: "Study Accession",
                field: "study_accession",
                colId: "study_accession",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: ncbirenderer,
            },
            {
                headerName: "# Experiments",
                field: "number_experiments",
                colId: "number_experiments",
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                width: 150,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Sequencing Tech.",
                field: "sequencing_assay",
                colId: "sequencing_assay",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Disease",
                field: "disease",
                colId: "disease",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Summary",
                field: "summary",
                colId: "summary",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Clinical",
                field: "has_clinical",
                colId: "has_clinical",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                width: 120,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Availability",
                field: "availability",
                colId: "availability",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                width: 130,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Submission Date",
                field: "submission_date",
                colId: "submission_date",
                filter: "agDateColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Public Date",
                field: "public_date",
                colId: "public_date",
                filter: "agDateColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Genome",
                field: "genome",
                colId: "genome",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Organ",
                field: "organ",
                colId: "organ",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Tissue",
                field: "tissue",
                colId: "tissue",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Cell",
                field: "cell",
                colId: "cell",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Cell Tissue Type",
                field: "cell_tissue_type",
                colId: "cell_tissue_type",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Cell Type",
                field: "cell_type",
                colId: "cell_type",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Sequencing Type",
                field: "omics",
                colId: "omics",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Area of Interest",
                field: "interest_area",
                colId: "interest_area",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "TA Lvl 1",
                field: "therapeutic_area_lvl1",
                colId: "therapeutic_area_lvl1",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "TA Lvl 2",
                field: "therapeutic_area_lvl2",
                colId: "therapeutic_area_lvl2",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Source",
                field: "db_name",
                colId: "db_name",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                width: 120,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
        ],
    },
    {
        headerName: "Keywords & Ontology IDs",
        headerClass: "keywordcolumn",
        children: [
            {
                headerName: "MeSH Keywords",
                field: "mesh_keywords",
                colId: "mesh_keywords",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Mondo Keywords",
                field: "mondo_keywords",
                colId: "mondo_keywords",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "UMLS Keywords",
                field: "umls_keywords",
                colId: "umls_keywords",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "DOID",
                field: "doid_ids",
                colId: "doid_ids",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "UBERON",
                field: "uberon_ids",
                colId: "uberon_ids",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "CL",
                field: "cl_ids",
                colId: "cl_ids",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
        ],
    },
    {
        headerName: "Article Information",
        headerClass: "articlecolumn",
        children: [
            {
                headerName: "Title",
                field: "pmid_title",
                colId: "pmid_title",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "PMID",
                field: "pmid",
                colId: "pmid",
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                cellRenderer: pmidrenderer,
            },
            {
                headerName: "PMCID",
                field: "pmcid",
                colId: "pmcid",
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                cellRenderer: pmcrenderer,
            },
            {
                headerName: "DOI",
                field: "doi",
                colId: "doi",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: doirenderer,
            },
            {
                headerName: "Abstract",
                field: "abstract",
                colId: "abstract",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Last Author",
                field: "last_authors",
                colId: "last_authors",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "All Authors",
                field: "authors",
                colId: "authors",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Year",
                field: "year",
                colId: "year",
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Publication",
                field: "journal",
                colId: "journal",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "ISSN",
                field: "issn",
                colId: "issn",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Publication Impact Factor",
                field: "impact_factor",
                colId: "impact_factor",
                filter: "agNumberColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Institute",
                field: "institute",
                colId: "institute",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "URL",
                field: "url",
                colId: "url",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: urlrenderer,
            },
            {
                headerName: "PDF URL",
                field: "pdf_url",
                colId: "pdf_url",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: urlrenderer,
            },
        ],
    },
    {
        headerName: "Sampling Information",
        headerClass: "samplingcolumn",
        children: [
            {
                headerName: "Runs Sampled",
                field: "runs_sampled",
                colId: "runs_sampled",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: ncbirenderer,
            },
            {
                headerName: "Library Layout",
                field: "library_layout",
                colId: "library_layout",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "File Types",
                field: "file_type",
                colId: "file_type",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                cellRenderer: textPopOver,
            },
            {
                headerName: "Phred 20%",
                field: "phred_20",
                colId: "phred_20",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Phred 30%",
                field: "phred_30",
                colId: "phred_30",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
                cellRenderer: textPopOver,
            },
            {
                headerName: "Search",
                field: "search",
                colId: "search",
                filter: "agTextColumnFilter",
                floatingFilter: true,
                columnGroupShow: "open",
            },
        ],
    },
]
};

export { useDefaultColDef, gridConfig };
