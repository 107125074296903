import React from "react";

interface NcbiRendererProps {
  value?: string | null;
}

const NcbiRenderer: React.FC<NcbiRendererProps> = ({ value }) => {
  if (!value || value === "-" || value === "") return null;

  if (value.includes(" | ")) {
    const ncbis = value.split(" | ");

    return (
      <div>
        {ncbis.map((ncbi, index) => (
          <React.Fragment key={ncbi}>
            <a
              href={`https://www.ncbi.nlm.nih.gov/search/all/?term=${ncbi}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ncbi}
            </a>
            {index !== ncbis.length - 1 && " | "}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div>
      <a
        href={`https://www.ncbi.nlm.nih.gov/search/all/?term=${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </div>
  );
};

export default NcbiRenderer;
