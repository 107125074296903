import React from "react";
import BarChart from "./components/charts/BarChart"
import PieChart from "./components/charts/PieChart";
import TimeSeries from "./components/charts/TimeSeries"

import "./css/OmicsDashboard.css"

type DashboardProps = {
    gridRef: any;
    filterState: any;
};

const Dashboard: React.FC<DashboardProps> = ({ gridRef, filterState }) => {
    return (
        <div className="flex-container">
            <div className="flex-item flex-item-pie">
                <PieChart gridRef={gridRef} filterState={filterState} field={'interest_area'} />
            </div>
            <div className="flex-item">
                <BarChart gridRef={gridRef} filterState={filterState} />
            </div>
            <div className="flex-item flex-item-pie">
                <PieChart gridRef={gridRef} filterState={filterState} field={'sequencing_assay'} />
            </div>
            <div className="flex-item">
                <TimeSeries filterState={filterState} />
            </div>
        </div>
    );
};

export default Dashboard;
