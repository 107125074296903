import React, { useState } from "react";
import { BsInfoCircle, BsXLg } from "react-icons/bs";
import "../../css/MyCreditsButton.css";

type MyCreditsButtonProps = {
  onClick?: () => void;
  credits: number;
};

type MyModalProps = {
  onClose: () => void;
};

function MyModal({ onClose }: MyModalProps) {
  return (
    <div className="modal-container2">
      <BsXLg className="close-icon" onClick={onClose} />
      <h2 className="modal-header">HOW DOES IT WORK?</h2>
      <p className="modal-text">
        You will require a certain number of credits to process selected
        datasets. Each dataset is associated with a number of runs (1 run = 1
        credit) and your counter displays remaining available credits as well as
        credits from your current selection.
      </p>
      <div className="info-section">
        <p className="info-text">
          Please contact us for any inquiry regarding account credits
        </p>
        <button
          className="contact-button"
          onClick={() => {
            window.location.href = "mailto:catalog@deeplife.co";
          }}
        >
          Contact us
        </button>
      </div>
    </div>
  );
}

function MyCreditsButton({ onClick, credits }: MyCreditsButtonProps) {
  const [isClicked, setIsClicked] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    onClick && onClick();
  };

  return (
    <>
      <button
        className={`my-credits-button ${isClicked ? "clicked" : ""}`}
        onClick={handleModalOpen}
      >
        <BsInfoCircle />
        <span className="credit-details">My available credits:</span>
        <span className="credit-details white">{credits}</span>
      </button>
      {showModal && (
          <MyModal onClose={handleModalClose} />
      )}
    </>
  );
}

export default MyCreditsButton;
