import React, { Component } from 'react';

interface DoiRendererProps {
  value?: string | null;
}

export default class DoiRenderer extends Component<DoiRendererProps> {

  renderElement() {
    const { value } = this.props;

    if (typeof value === 'string' && value.includes(' | ')) {
      const dois: string[] = value.split(' | ');
      const doisLinks: JSX.Element[] = [];

      dois.forEach((doi) => {
        doisLinks.push(<a key={doi} href={"https://doi.org/" + doi} target="_blank" rel="noopener noreferrer">{doi}</a>);
        doisLinks.push(<span key={"sep_" + doi}> | </span>);
      });

      doisLinks.pop();
      return doisLinks;
    } else if (value === '' || value === '-' || value === null) {
      return null;
    } else {
      return <a key={value} href={"https://doi.org/" + value} target="_blank" rel="noopener noreferrer">{value}</a>;
    }
  }

  render() {
    return (
      <div>
        { this.renderElement() }
      </div>
    );
  }
}