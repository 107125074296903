import React, { useState } from "react";
import { BsInfoCircle, BsXLg } from "react-icons/bs";
import "../../css/MyDatasetsButton.css";

type MyDatasetsButtonProps = {
  onClick?: () => void;
  numFiltered: number;
  filterModel: any;
};

function formatKey(key: any) {
  return key
    .split("_")
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function MyModal({ filterModel }: { filterModel: any }) {
  return (
    <div className="my-modal">
      <h2>HOW DOES FILTERING WORK?</h2>
      <p>
        Use the filters to narrow down the datasets that are relevant to you.
        <br />
        The number of filtered datasets will update accordingly.
      </p>
      <h3>Current Filters:</h3>
      <div>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Key
              </th>
              <th
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Filter
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(filterModel).map((key) => (
              <tr key={key}>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {formatKey(key)}
                </td>
                <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                  {filterModel[key].filter ? filterModel[key].filter : "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function MyDatasetsButton({
  numFiltered,
  filterModel,
}: MyDatasetsButtonProps) {
  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  return (
    <>
      <BsInfoCircle className="bg_icon" onClick={handleModalOpen} />
      <div className="my-datasets-info">
        <p>Filtered Datasets:</p>
        <span>{numFiltered}</span>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-container">
            <MyModal filterModel={filterModel} />
            <BsXLg className="close-icon" onClick={() => setShowModal(false)} />
          </div>
        </div>
      )}
    </>
  );
}

export default MyDatasetsButton;
