import React from 'react';

interface PmidRendererProps {
  value?: string | null;
}

const PmidRenderer: React.FC<PmidRendererProps> = ({ value }) => {
  if (!value || ['-', ''].includes(value)) return null;

  if (value.includes(' | ')) {
    const pmids = value.split(' | ');
    return (
      <div>
        {pmids.map((pmid, index) => (
          <React.Fragment key={pmid}>
            <a href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`} target="_blank" rel="noopener noreferrer">
              {pmid}
            </a>
            {index !== pmids.length - 1 && ' | '}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div>
      <a href={`https://pubmed.ncbi.nlm.nih.gov/${value}`} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    </div>
  );
}

export default PmidRenderer;