import React, { useState } from "react";
import "../../css/ResetButton.css";

export const ResetButton = ({ title, toggled, setToggled, onClick }: any) => {
  const callback = () => {
    const newToggledState = !toggled;
    setToggled(newToggledState); // Update the toggled state

    onClick(newToggledState);
  };

  return (
    <button
      className={`resetbutton ${toggled ? "active" : ""}`}
      onClick={callback}
    >
      <i className="fas fa-undo"></i>
      {toggled ? "Clear" : "Clear"}
    </button>
  );
};

export default ResetButton;
