import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../css/AgGridOverride.css";
import { gridConfig, useDefaultColDef } from "./OmicsGridConfig";
import { useOnGridReady } from "./OmicsGridReady";
import { updateDataset } from "../api/api";

type Props = {
  gridRef: any;
  onSelectionChanged: any;
  setFilterState: any;
};

const OmicsGrid = ({ gridRef, onSelectionChanged, setFilterState }: Props) => {
  const defaultColDef = useDefaultColDef();
  const onGridReady = useOnGridReady();

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "100%",
        width: "100%",
        padding: "1rem",
      }}
    >
      <AgGridReact
        ref={gridRef}
        gridOptions={gridConfig}
        defaultColDef={defaultColDef}
        getRowId={(params) => params.data.id.toString()}
        rowModelType={"serverSide"}
        rowSelection={"multiple"}
        suppressRowClickSelection={true}
        tooltipShowDelay={500}
        tooltipMouseTrack={true}
        animateRows={true}
        pagination
        suppressAggFuncInHeader={true}
        onGridReady={onGridReady}
        onFirstDataRendered={(params) => {
          params.api.forEachNode((node) => {
            if (node.data.status === "Preselected") {
              node.setSelected(true);
            }
          });
        }}
        onFilterChanged={(event) => {
          // if (event.source === 'columnFilter') {
          const currentFilter = gridRef.current!.api.getFilterModel();
          setFilterState(currentFilter);
          // }
        }}
        onSelectionChanged={onSelectionChanged}
        isRowSelectable={(rowNode) =>
          !!rowNode.data &&
          (rowNode.data.status === "" ||
            rowNode.data.status === null ||
            rowNode.data.status === "Preselected" ||
            rowNode.data.helperStatus === "In progress")
        }
        onRowSelected={(event) => {
          console.log(event.node.isSelected());
          if (
            event.node.isSelected() &&
            event.node.data.status !== "Preselected"
          ) {
            event.node.updateData({
              ...event.node.data,
              status: "Preselected",
            });
            updateDataset(
              event.node.data,
              "Preselected",
              localStorage.getItem("catalog_access_token")
            );
          } else if (
            !event.node.isSelected() &&
            event.node.data.status === "Preselected"
          ) {
            event.node.updateData({ ...event.node.data, status: "" });
            updateDataset(
              event.node.data,
              "",
              localStorage.getItem("catalog_access_token")
            );
          } else if (
            !event.node.isSelected() &&
            event.node.data.helperStatus === "In progress"
          ) {
            // remove helperStatus to make the row not selectable (look at isRowSelectable)
            event.node.updateData({ ...event.node.data, helperStatus: "" });
          }
        }}
      />
    </div>
  );
};

export default OmicsGrid;
