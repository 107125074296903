import React, { FC } from "react";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import "../../css/App.css";

interface TextPopOverProps {
  value: string;
}

const TextPopOver: FC<TextPopOverProps> = ({ value }) => {
  const cssClasses = "ag-grid-popover";

  return (
    <div>
      <Popover placement="bottom">
        <PopoverHandler>
          <span>{value}</span>
        </PopoverHandler>
        <PopoverContent>
          <div className={cssClasses}>{value}</div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default TextPopOver;
