import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import axios from "axios";

function MyDropzone() {
    const onDrop = useCallback((acceptedFiles: any) => {
        var formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${localStorage.getItem("catalog_access_token")}`,
            }
        })

        console.log(acceptedFiles)
    }, [])
    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone(
        {
            onDrop,
            accept: {
                'text/plain': ['.txt'],
                'text/csv': ['.csv']
            }
        });

    const acceptedFileItems = acceptedFiles.map((file: any) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        <li key={"file.path"}>
            {"file.path"} - {file.size} bytes
            <ul>
                {errors.map((e) => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    return (
        <section className="container">
            <div
                {...getRootProps({className: "dropzone"})}
                style={{textAlign: "center", padding: "40px"}}
            >
                <input {...getInputProps()} />
                <img
                    src="/download.png"
                    alt="Download Logo"
                    style={{
                        maxWidth: "25px",
                        maxHeight: "25px",
                        marginBottom: "30px",
                    }}
                />
                <div style={{fontWeight: "700", letterSpacing: "2px"}}>
                    SUBMIT A FILE
                </div>
                <div
                    style={{
                        color: "grey",
                        fontWeight: "500",
                        fontSize: "12px",
                        marginTop: "10px",
                    }}
                >
                    TXT or CSV files
                </div>
                <div
                    style={{
                        display: "flex",
                        marginTop: "40px",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#464DF2",
                        color: "white",
                        borderRadius: "30px",
                        padding: "0.5rem",
                        letterSpacing: "2px",
                        border: "none",
                        outline: "none",
                        cursor: "pointer",
                        height: "40px",
                        fontSize: "14px",
                        fontWeight: "500",
                        // marginBottom: "1rem",
                        // fontFamily: 'Roboto'
                    }}
                >
                    SELECT AND SUBMIT YOUR FILE
                </div>
            </div>
            {Boolean(acceptedFileItems.length || fileRejectionItems.length) && !(fileRejectionItems.length > 0) && (
                <aside>
                    <h4>Upload successful!</h4>
                    <ul>{acceptedFileItems}</ul>
                </aside>
            )}
            {fileRejectionItems.length > 0 && (
                <aside style={{color: 'red'}}>
                    <h4>Rejected files</h4>
                    <ul>{fileRejectionItems}</ul>
                </aside>
            )}
        </section>
    );
}

export default MyDropzone;