import React, { useState, useCallback } from "react";
import FilterButton from "./components/buttons/FilterButton";
import OmicsGrid from "./components/OmicsGrid";
import UploadButton from "./components/buttons/UploadButton";
import "./css/Explorer.css"

type ExplorerProps = {
  gridRef: any;
  onSelectionChanged: any;
  setFilterState: any;
};

const Explorer: React.FC<ExplorerProps> = ({
  gridRef,
  onSelectionChanged,
  setFilterState,
}) => {
  const [isToggledForHumanClinical, setIsToggledForHumanClinical] =
    useState(false);
  const [isToggledForAvailable, setIsToggledForAvailable] = useState(false);
  const [isToggledForProcessable, setIsToggledForProcessable] = useState(false);
  const [isToggledForPreselected, setIsToggledForPreselected] = useState(false);
  const handleFilterClick = useCallback(
    (filterKey: string, filterValue: string | number, toggled: boolean) => {
      const currentFilter = gridRef.current!.api.getFilterModel();

      if (toggled) {
        currentFilter[filterKey] = { type: "equals", filter: filterValue };
      } else {
        delete currentFilter[filterKey];
      }

      gridRef.current!.api.setFilterModel(currentFilter);
      gridRef.current!.api.onFilterChanged();
    },
    []
  );

  const handleSearchChange = (event: any) => {
    const currentFilter = gridRef.current!.api.getFilterModel();
    currentFilter["search"] = { type: "equals", filter: event.target.value };
    gridRef.current!.api.setFilterModel(currentFilter);
    gridRef.current!.api.onFilterChanged();
  };

  return (
    <div className="explorer-container">
      <div className="filters filters-container">
        <input
          type="text"
          id="filter-text-box"
          placeholder="Search..."
          style={{
            marginLeft: "30px",
          }}
          onChange={handleSearchChange}
        />
        <FilterButton
          title={"Human clinical datasets"}
          onClick={() =>
            handleFilterClick("human_clinical", "1", !isToggledForHumanClinical)
          }
          toggled={isToggledForHumanClinical}
          setToggled={setIsToggledForHumanClinical}
        />
        <FilterButton
          title={"Available datasets"}
          onClick={() =>
            handleFilterClick("availability", "YES", !isToggledForAvailable)
          }
          toggled={isToggledForAvailable}
          setToggled={setIsToggledForAvailable}
        />
        <FilterButton
          title={"Processable datasets"}
          onClick={() =>
            handleFilterClick("processable", "YES", !isToggledForProcessable)
          }
          toggled={isToggledForProcessable}
          setToggled={setIsToggledForProcessable}
        />
        <FilterButton
          title={"Preselected datasets"}
          onClick={() =>
            handleFilterClick("status", "Preselected", !isToggledForPreselected)
          }
          toggled={isToggledForPreselected}
          setToggled={setIsToggledForPreselected}
        />
        <UploadButton />
      </div>
      <div className="grid-container">
        <OmicsGrid
            gridRef={gridRef}
            onSelectionChanged={onSelectionChanged}
            setFilterState={setFilterState}
        />
      </div>
    </div>
  );
};

export default Explorer;
