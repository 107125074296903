import React, { useCallback, useEffect, useState } from "react";
import { PieChart as MinimalPieChart } from "react-minimal-pie-chart";
import ResetButton from "../buttons/ResetButton";
import FilterTableButton from "../buttons/FilterTableButton";
import SortTableButton from "../buttons/SortTableButton";
import chroma from "chroma-js";
import axios from "axios";

import "../../css/PieChart.css";

type PieChartProps = {
  gridRef: any;
  filterState: any;
  field: string;
};

const PieChart: React.FC<PieChartProps> = ({ gridRef, filterState, field }) => {
  const [chartData, setChartData] = useState([]);
  const [checkedIndices, setCheckedIndices] = useState<number[]>([]);
  const [isClearButtonActive, setIsClearButtonActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [valueThreshold, setValueThreshold] = useState("");
  const [selectedField, setSelectedField] = useState(field);
  const [isFilterMenuVisible, setIsFilterMenuVisible] = useState(false);
  const [isSortModalVisible, setIsSortModalVisible] = useState(false);
  const [minDatasets, setMinDatasets] = useState("");
  const [maxDatasets, setMaxDatasets] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selectedRange, setSelectedRange] = useState("all");
  const [isLoading, setIsLoading] = useState(false);

  // Event handler for when the search term changes
  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
    setIsClearButtonActive(true);
  };

  // Function to filter the data based on the search term
  const getFilteredData = useCallback(() => {
    let filteredData = chartData;

    if (searchTerm) {
      console.log("filteredData", filteredData);
      filteredData = filteredData.filter((item: { label: string }) =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (valueThreshold !== null) {
      const threshold = Number(valueThreshold);
      filteredData = filteredData.filter(
        (item: { value: number }) => item.value >= threshold
      );
    }

    if (sortOrder === "asc" || sortOrder === "desc") {
      const multiplier = sortOrder === "asc" ? 1 : -1;
      filteredData.sort(
        (a: { value: number }, b: { value: number }) =>
          (a.value - b.value) * multiplier
      );
    } else if (sortOrder === "alpha") {
      filteredData.sort((a: { label: string }, b: { label: string }) =>
        a.label.localeCompare(b.label)
      );
    }

    // Filter by value threshold
    if (selectedRange && selectedRange !== "all") {
      let min = 0;
      let max = Infinity;

      if (selectedRange.includes("-")) {
        const [rangeMin, rangeMax] = selectedRange.split("-").map(Number);
        min = rangeMin;
        max = rangeMax;
      } else {
        min = Number(selectedRange);
      }

      filteredData = filteredData.filter(
        (item: { value: number }) => item.value >= min && item.value <= max
      );
    }

    if (minDatasets !== "" && maxDatasets !== "") {
      const min = Number(minDatasets);
      const max = Number(maxDatasets);
      filteredData = filteredData.filter(
        (item: { value: number }) => item.value >= min && item.value <= max
      );
    }

    return filteredData;
  }, [searchTerm, valueThreshold, selectedRange, sortOrder, chartData]);

  const handleSortOrderChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setSortOrder(value);
  };

  const handleCheckboxChange = (
    label: string,
    checked: boolean,
    index: number
  ) => {
    const currentFilter = gridRef.current!.api.getFilterModel();

    let existingFilters: string[] = [];
    if (currentFilter[selectedField] && currentFilter[selectedField].filter) {
      existingFilters = currentFilter[selectedField].filter.split(",");
    }

    if (checked) {
      existingFilters.push(label);
      setCheckedIndices((prevIndices) => [...prevIndices, index]);
    } else {
      const ind = existingFilters.indexOf(label);
      if (ind > -1) {
        existingFilters.splice(ind, 1);
      }

      setCheckedIndices((prevIndices) =>
        prevIndices.filter((i) => i !== index)
      );
    }

    // Update or delete the filter
    if (existingFilters.length > 0) {
      currentFilter[selectedField] = {
        filterType: "text",
        type: "contains",
        filter: existingFilters.join(","),
      };
    } else {
      delete currentFilter[selectedField];
    }

    gridRef.current!.api.setFilterModel(currentFilter);
    gridRef.current!.api.onFilterChanged();
  };

  const toggleFilterMenu = () => {
    setIsFilterMenuVisible(!isFilterMenuVisible);
  };

  const toggleSortModal = () => {
    setIsSortModalVisible(!isSortModalVisible);
  };

  // Function to update the toggle state of the ClearFilterButton
  const updateClearButtonState = useCallback(() => {
    const anyCheckboxChecked = Object.values(checkedIndices).some(
      (value) => value
    );
    const anyInputFilled = searchTerm !== "" || valueThreshold !== "";
    setIsClearButtonActive(anyCheckboxChecked || anyInputFilled);
  }, [checkedIndices, searchTerm, valueThreshold]);

  // Clear all filters and update the toggle state
  const handleClearFilters = () => {
    // Reset the search term and value threshold
    setSearchTerm("");
    setValueThreshold("");
    setCheckedIndices([]);
    setIsClearButtonActive(false);

    // Update the filter model in the grid to remove filters
    const currentFilter = gridRef.current!.api.getFilterModel();
    if (currentFilter[selectedField]) {
      delete currentFilter[selectedField];
      gridRef.current!.api.setFilterModel(currentFilter);
      gridRef.current!.api.onFilterChanged();
    }
  };

  useEffect(() => {
    setSelectedField(field);
  }, [field]);

  useEffect(() => {
    // Fetch data from the backend based on the selected field
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let url = `${process.env.REACT_APP_BACKEND_URL}/count_datasets?field=${selectedField}`;
        if (filterState) {
          for (const [key, value] of Object.entries(filterState)) {
            if (value && selectedField !== key) {
              // @ts-ignore
              url += `&${key}=${value.filter}`;
            }
          }
        }
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(
              "catalog_access_token"
            )}`,
          },
        });

        const data = response.data.map((item: any) => ({
          label: item[selectedField],
          value: item.count,
          legendLabel: `${item[selectedField]} ${item.count}`,
        }));

        const colorScale = chroma
          .scale(["#F6BE00", "#4EA599", "#4D1DD9", "#693C5E", "#8B0000"])
          .mode("lch")
          .colors(data.length);

        setChartData(
          data.map((item: any, index: number) => ({
            index: index,
            label: item.label,
            title: item.label,
            value: item.value,
            color: colorScale[index % colorScale.length],
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Stop loading regardless of success or failure
      }
    };

    fetchData();
  }, [selectedField]);

  // Update the toggle state whenever checkboxes or inputs change
  useEffect(() => {
    const anyCheckboxChecked = checkedIndices.length > 0;
    const anyInputFilled = searchTerm !== "" || valueThreshold !== "";
    setIsClearButtonActive(anyCheckboxChecked || anyInputFilled);
  }, [checkedIndices, searchTerm, valueThreshold]);

  return (
    <div className="inner-flex-container">
      {isLoading && (
        <div className="loading-spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="top-row">
        <div className="left-aligned-container">
          <select
            className="dropdown"
            value={selectedField}
            onChange={(e) => {
              setSelectedField(e.target.value);
              setCheckedIndices([]);
            }}
          >
            <option value="organ">Organ</option>
            <option value="disease">Disease</option>
            <option value="sequencing_assay">Technology</option>
            <option value="organism_name">Organism</option>
            <option value="interest_area">Interest area</option>
          </select>
        </div>
        <div className="right-aligned-container">
          <input
            type="text"
            className="pill-search"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
          <div className="icon-menu">
            <FilterTableButton onClick={toggleFilterMenu} />
            {isFilterMenuVisible && (
              <div className="filter-modal">
                <div className="filter-menu-header">
                  <h3># datasets</h3>
                  <button
                    onClick={toggleFilterMenu}
                    aria-label="Close filter menu"
                  ></button>
                </div>
                <div className="filter-menu-content">
                  <div className="range-inputs">
                    <input
                      type="number"
                      value={minDatasets}
                      onChange={(e) => setMinDatasets(e.target.value)}
                      placeholder="Min"
                    />
                    <input
                      type="number"
                      value={maxDatasets}
                      onChange={(e) => setMaxDatasets(e.target.value)}
                      placeholder="Max"
                    />
                  </div>
                  <div className="range-selector">
                    <div className="range-option">
                      <input
                        type="radio"
                        id="range1"
                        name="dataset-range"
                        value="all"
                        onChange={(e) => setSelectedRange(e.target.value)}
                      />
                      <label htmlFor="range1">all</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="range1"
                        name="dataset-range"
                        value="10000"
                        onChange={(e) => setSelectedRange(e.target.value)}
                      />
                      <label htmlFor="range1">+10 000</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="range2"
                        name="dataset-range"
                        value="5000-10000"
                        onChange={(e) => setSelectedRange(e.target.value)}
                      />
                      <label htmlFor="range2">10 000 - 5000</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="range2"
                        name="dataset-range"
                        value="500-5000"
                        onChange={(e) => setSelectedRange(e.target.value)}
                      />
                      <label htmlFor="range2">5000 - 500</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="range2"
                        name="dataset-range"
                        value="100-500"
                        onChange={(e) => setSelectedRange(e.target.value)}
                      />
                      <label htmlFor="range2">500 - 100</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="range2"
                        name="dataset-range"
                        value="100"
                        onChange={(e) => setSelectedRange(e.target.value)}
                      />
                      <label htmlFor="range2">+100</label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="icon-menu">
            <SortTableButton onClick={toggleSortModal} />
            {isSortModalVisible && (
              <div className="sort-modal">
                <div className="filter-menu-header">
                  <h3># Sort by</h3>
                  <button
                    onClick={toggleSortModal}
                    aria-label="Close Sort menu"
                  ></button>
                </div>
                <div className="sort-modal-content">
                  <div className="range-selector">
                    <div className="range-option">
                      <input
                        type="radio"
                        id="sort1"
                        name="sortOrder"
                        value="asc"
                        onChange={handleSortOrderChange}
                        checked={sortOrder === "asc"}
                      />
                      <label htmlFor="sort1">ascending datasets</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="sort1"
                        name="sortOrder"
                        value="desc"
                        onChange={handleSortOrderChange}
                        checked={sortOrder === "desc"}
                      />
                      <label htmlFor="sort1">descending datasets</label>
                    </div>
                    <div className="range-option">
                      <input
                        type="radio"
                        id="sort1"
                        name="sortOrder"
                        value="alpha"
                        onChange={handleSortOrderChange}
                        checked={sortOrder === "alpha"}
                      />
                      <label htmlFor="sort1">alphabetical order</label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <ResetButton
            title="Clear"
            toggled={isClearButtonActive}
            setToggled={setIsClearButtonActive}
            onClick={handleClearFilters}
          />
        </div>
      </div>
      <div className="bottom-row">
        <div className="column">
          <MinimalPieChart
            data={chartData}
            animationDuration={100}
            segmentsStyle={(index) => {
              const isChecked = checkedIndices.includes(index);
              return {
                transition: "stroke .3s, strokeWidth .3s",
                stroke: isChecked ? "#DBE2E9" : "",
              };
            }}
            style={{
              height: "90%",
              width: "100%",
            }}
            segmentsShift={(index) => {
              return checkedIndices.includes(index) ? 4 : 0;
            }}
            radius={45}
            labelPosition={60}
          />
        </div>
        <div className="column">
          <table className="checkboxes">
            <thead>
              <tr>
                <th>Name</th>
                <th># Datasets</th>
              </tr>
            </thead>
          </table>
          <div className="table-body-container">
            <table className="checkboxes">
              <tbody>
                {getFilteredData().map((item: any) => (
                  <tr key={item.index}>
                    <td>
                      <input
                        type="checkbox"
                        id={`checkbox-${item.index}`}
                        checked={checkedIndices.includes(item.index)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            item.title,
                            e.target.checked,
                            item.index
                          )
                        }
                      />
                      <label
                        htmlFor={`checkbox-${item.index}`}
                        className="checkbox-label"
                        title={item.title}
                      >
                        {item.title}
                      </label>
                    </td>
                    <td>{item.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
