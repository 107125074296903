import React, { useState } from "react";
import { BsXLg } from "react-icons/bs";
import axios from "axios";
import "../../css/MySelectionButton.css";

type MySelectionButtonProps = {
  gridRef: any;
  numCredits: number;
  myCredits: number;
  email: string;
  onSubmit: any;
};

type MyModalButtonProps = {
  gridRef: any;
  numCredits: number;
  onSubmit: any;
  onClose: () => void;
};

function MyModal({
  gridRef,
  numCredits,
  onSubmit,
  onClose,
}: MyModalButtonProps) {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  return (
    <div className="modal-container">
      <BsXLg className="close-icon" onClick={onClose} />
      <h2 className="modal-header">MY SELECTION SUMMARY</h2>
      {isSubmit ? (
        <p className="modal-text">Thank you</p>
      ) : (
        <>
          <p className="modal-text">You are about to submit</p>
          <p className="modal-number">
            {gridRef.current.api.getSelectedRows().length}
          </p>
          <p className="modal-small-text"> datasets</p>
          <p className="modal-credits">
            {numCredits} credits <br />
          </p>
          <button
            className={`submit-button ${
              isProcessing ? "submit-button-disabled" : ""
            }`}
            disabled={isProcessing}
            onClick={async () => {
              try {
                setIsProcessing(true);
                await axios.put(
                  `${process.env.REACT_APP_BACKEND_URL}/datasets/`,
                  JSON.stringify(
                    gridRef.current.api
                      .getSelectedRows()
                      .map((obj: any) => ({ ...obj, status: "In progress" }))
                  ),
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${localStorage.getItem(
                        "catalog_access_token"
                      )}`,
                    },
                  }
                );

                const selectedNodes = gridRef.current.api.getSelectedNodes();
                for (let node of selectedNodes) {
                  // introduce helperStatus to be able to make deselection, look at logic in OmicsGrid.tsx
                  node.updateData({
                    ...node.data,
                    status: "In progress",
                    helperStatus: "In progress",
                  });
                  node.setSelected(false);
                }

                onSubmit(); // Refetch credits
                setIsSubmit(true);
              } catch (error) {
                console.error("Error submitting datasets:", error);
                // Handle the error (maybe show a notification to the user)
              } finally {
                setIsProcessing(false); // Set it back to false regardless of success or error
              }
            }}
          >
            {isProcessing ? "Processing request..." : "Send Request"}
          </button>
        </>
      )}
      <div className="info-section">
        <p className="info-text">
          Our team will review your request and come
          back to you for final validation. For further question questions,
          please contact us at
        </p>
        <a href="mailto:catalog@deeplife.co" className="email-link">
          catalog@deeplife.co
        </a>
      </div>
    </div>
  );
}

function MySelectionButton({
  gridRef,
  numCredits,
  myCredits,
  email,
  onSubmit,
}: MySelectionButtonProps) {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const canSubmit = () => {
    return (
      (email.startsWith("ebuys") || email.endsWith("deeplife.co")) &&
      gridRef.current.api.getSelectedRows().length > 0 &&
      numCredits <= myCredits
    );
  };

  if (!gridRef.current) return <div></div>;

  return (
    <>
      <div className="my-datasets-info">
        <p>Selected Runs:</p>
        <span>{numCredits}</span>
      </div>
      <button
        onClick={canSubmit() ? handleShowModal : undefined}
      >
        Submit selected datasets
      </button>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-container">
            <MyModal
              numCredits={numCredits}
              gridRef={gridRef}
              onSubmit={onSubmit}
              onClose={handleCloseModal}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default MySelectionButton;
