import React from "react";

interface PmcRendererProps {
  value?: string | null;
}

const PmcRenderer: React.FC<PmcRendererProps> = ({ value }) => {
  if (!value || ["-", ""].includes(value)) return null;

  if (value.includes(" | ")) {
    const pmcs = value.split(" | ");
    return (
      <div>
        {pmcs.map((pmc, index) => (
          <React.Fragment key={pmc}>
            <a
              href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${pmc}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {pmc}
            </a>
            {index !== pmcs.length - 1 && " | "}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <div>
      <a
        href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${value}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    </div>
  );
};

export default PmcRenderer;
