import React, { FC } from "react";

interface UrlRendererProps {
  value: string;
}

const UrlRenderer: FC<UrlRendererProps> = ({ value }) => {
  const renderElement = () => {
    if (typeof value === "string" && value.includes(" | ")) {
      let urls = value.split(" | ");
      return urls.map((url, index) => (
        <React.Fragment key={index}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
          {index < urls.length - 1 && " | "}
        </React.Fragment>
      ));
    } else if (value === "" || value === "-" || value === null) {
      return null;
    } else {
      return (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    }
  };

  return <div>{renderElement()}</div>;
};

export default UrlRenderer;
