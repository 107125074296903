import React from "react";
import "../../css/FilterTableButton.css";

type FilterTableButtonProps = {
  onClick: () => void; // Define the type for the onClick prop
};

const FilterTableButton: React.FC<FilterTableButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className="filter-button">
      <i className="fas fa-filter"></i>
    </button>
  );
};

export default FilterTableButton;
