function parseJwt(token) {
  try {
    // Split the token into header, payload, and signature
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Token format is invalid');
    }

    // Base64Url decode the payload
    const payloadJson = atob(parts[1].replace('-', '+').replace('_', '/'));
    
    // Parse the JSON payload
    const payload = JSON.parse(payloadJson);

    return payload;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export function isJwtTokenExpired(token) {
  const payload = parseJwt(token);

  if (!payload) {
    return true; // Assume the token is expired or invalid if there's an error during parsing
  }

  // Get the current timestamp in seconds
  const currentTime = Math.floor(Date.now() / 1000);

  // Check if the token has expired
  if (payload.exp && payload.exp < currentTime) {
    return true;
  } else {
    return false;
  }
}