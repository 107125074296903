import React from "react";

interface ContainerProps {
  children: React.ReactNode;
}

const CenteredContainer: React.FC<ContainerProps> = ({ children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
      marginBottom: "10px",
    }}
  >
    {children}
  </div>
);

const TermsText: React.FC = () => (
  <div className="tc-style" style={termsTextStyle}>
    The data catalog is proprietary information of DeepLife and is protected by
    intellectual property rights. Users may not use, reproduce, extract or
    browse it for purposes other than selecting datasets for ingestion and
    processing by DeepLife.
  </div>
);

const termsTextStyle = {
  width: "100%",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingBottom: "1rem",
  color: "#c0c0c0",
  fontSize: "0.8rem",
};

export const TC: React.FC = () => (
  <div>
    <CenteredContainer>
      <TermsText />
    </CenteredContainer>
  </div>
);

export default TC;
