import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {AgChartsReact} from 'ag-charts-react';
import {AgChartOptions} from 'ag-charts-community';

import "../../css/TimeSeries.css"
import "../../css/OmicsDashboard.css"

type TimeSeriesProps = {
    filterState: any;
};


const TimeSeries: React.FC<TimeSeriesProps> = ({filterState}) => {
    const [options, setOptions] = useState<AgChartOptions>({
        title: {
            text: "Temporal Dataset Trends",
        },
        data: [],  // Initially, data is an empty array
        series: [
            {
                type: 'line', // Line chart
                xKey: 'pub_year',  // Updated key to match the data structure
                yKey: 'cumulativeCount',
                stroke: '#560bad',  // Set the color of the line to blue
                strokeWidth: 3,   // Set the thickness of the line
                marker: {
                    enabled: true,
                    fill: 'white',
                    stroke: '#560bad',
                    size: 0,
                },
                tooltip: {
                    enabled: true,
                    renderer: function (params) {
                        return `
                            <div class="tooltip-content">
                                <strong>Quarter Year:</strong> ${params.datum.quarter_year.split(' ')[1]}<br/>
                                <strong># Datasets:</strong> ${params.datum.count}<br/>
                                <strong>Cumulative Value:</strong> ${params.datum.cumulativeCount}
                            </div>`;
                    },
                }
            },
        ],
    });

    useEffect(() => {
        // Fetch data from the /count_datasets endpoint
        async function fetchData() {
            let url = `${process.env.REACT_APP_BACKEND_URL}/count_datasets?field=public_date`;
            if (filterState) {
                for (const [key, value] of Object.entries(filterState)) {
                    if (value && 'db_name' !== key) {
                        // @ts-ignore
                        url += `&${key}=${value.filter}`;
                    }
                }
            }
            const response = await axios.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('catalog_access_token')}`,
                },
            });
            const data = await response.data;

            let cumulativeSum = 0;
            const cumulativeData = data.map((item : any) => {
                const year = item.quarter_year.split(' ')[1];
                return { ...item, cumulativeCount: item.total_count_pub_year, year: year };
            })
            .filter((item: any) => item.year !== '')
            .map((item: any) => {
                cumulativeSum += item.count;
                return { ...item, cumulativeCount: cumulativeSum };
            });

            // Update the chart options with the fetched data
            setOptions(prevOptions => ({
                ...prevOptions,
                data: cumulativeData,
                axes: [
                    {
                        type: 'number',
                        position: 'left',
                        gridStyle: []
                    },
                    {
                        type: 'category',
                        position: 'bottom',
                        gridStyle: []
                    }
                ],
            }));
        }

        fetchData();
    }, [filterState]);  // Empty dependency array means this useEffect runs once when the component mounts

    return (
        <div className="chart-container">
            <AgChartsReact options={options}/>
        </div>
    );
};

export default TimeSeries;
