import React from "react";
import "../../css/SortTableButton.css";

type SortTableButtonProps = {
  onClick: () => void; // Define the type for the onClick prop
};

const SortTableButton: React.FC<SortTableButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className="filter-button">
      <i className="fa-solid fa-up-down"></i>
    </button>
  );
};

export default SortTableButton;
