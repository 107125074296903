import React, {useState} from "react";
import "../../css/FilterButton.css";

function MyModal() {
    return (
        <div className="modalMain">
            <h2 className="modalTitle">FILTER DETAILS</h2>
            <p className="modalInfo">
                By activating the Human Clinical Filter, you hide all non-human related
                Datasets whilst also filtering out specific keywords such as:
                <span className="keywords">
          PDX, PBMCs, CAR-T, hIPSC/Chimeric/Organoid cell tissue types.
        </span>
            </p>
            <div className="modalFooter">
                <p className="contactInfo">
                    Please contact us for any changes regarding this filter.
                </p>
                <a href="mailto:catalog@deeplife.co" className="contactButton">
                    Contact us
                </a>
            </div>
        </div>
    );
}

export const FilterButton = ({title, toggled, setToggled, onClick}: any) => {
    // const [isToggled, toggle] = useState(toggled);
    const [showModal, setShowModal] = useState(false);

    const callback = () => {
        setToggled(!toggled);
        onClick(!toggled);
    };

    return (
        <div className={toggled ? "filterdiv" : "filterdivdisabled"}>
            <label className="filtertoggle">
                <input
                    className="filterinput"
                    type="checkbox"
                    checked={toggled}
                    onChange={callback}
                />
                <span className="filterspan"></span>
            </label>
            <span className="filtertext">{title}</span>
        </div>
    );
};

export default FilterButton;
