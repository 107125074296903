import {useCallback} from "react";
import {GridReadyEvent} from "ag-grid-community";
import axios from "axios";

const pageSize = 100;

const getServerSideDatasource = (fetchData: any) => {
    return {
        getRows: (params: any) => {
            fetchData(params.request.startRow, params.request.endRow,
                params.success, params.request.filterModel, params.request.sortModel);
        },
    };
};

export const useOnGridReady = () => {
    return useCallback((gridParams: GridReadyEvent) => {
        const fetchData = (
            startRow: number,
            endRow: number,
            successCallback: (rowsThisPage: { rowData: any, rowCount: number }) => void,
            filters: any,
            sortModel: any
        ) => {
            const filterKeys = Object.keys(filters);

            let queryParams = [];

            filterKeys.forEach(key => {
                const filterObj = filters[key];
                switch (filterObj.filterType) {
                    case 'text':
                        if (filterObj.type === 'contains' || filterObj.type === 'equals') {
                            queryParams.push(`${key}=${filterObj.filter}`);
                        }
                        else if (filterObj.type === 'notContains') {
                            // TODO
                            break;
                        }
                        break;
                    case 'number':
                        if (filterObj.type === 'greaterThan') {
                            queryParams.push(`${key}_gt=${filterObj.filter}`);
                        } else if (filterObj.type === 'lessThan') {
                            queryParams.push(`${key}_lt=${filterObj.filter}`);
                        } else if (filterObj.type === 'equals') {
                            queryParams.push(`${key}=${filterObj.filter}`);
                        } // Add more conditions if needed
                        if (filterObj.filterTo) {
                            queryParams.push(`${key}_to=${filterObj.filterTo}`);
                        }
                        break;
                    case 'date':
                        const dateStr = filterObj.dateFrom.split(' ')[0]; // Convert date to 'YYYY-MM-DD'
                        if (filterObj.type === 'greaterThan' || filterObj.type === 'inRange') {
                            queryParams.push(`${key}_gt=${dateStr}`);
                        } else if (filterObj.type === 'lessThan') {
                            queryParams.push(`${key}_lt=${dateStr}`);
                        } else if (filterObj.type === 'equals') {
                            queryParams.push(`${key}=${dateStr}`);
                        } // Add more conditions if needed
                        if (filterObj.dateTo) {
                            const dateToStr = filterObj.dateTo.split(' ')[0];
                            queryParams.push(`${key}_to=${dateToStr}`);
                        }
                        break;
                    // Handle other filter types if needed
                }
            });

            if (sortModel.length > 0) {
                const sort_by = (sortModel[0].sort === 'asc' ? '' : '-') + sortModel[0].colId;
                queryParams.push(`sort_by=${sort_by}`);
            }

            const url = `${process.env.REACT_APP_BACKEND_URL}/datasets?page=${
                Math.floor(startRow / pageSize) + 1
            }&${queryParams.join('&')}`;

            const token = localStorage.getItem("catalog_access_token");
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,  // Add the authorization header with the token
            };

            axios
                .get(url, {headers: headers}) // Add the headers to the request
                .then((result) => {
                    const data = result.data.results;
                    successCallback({
                        rowData: data,
                        rowCount: result.data.count
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        console.warn("Requested page does not exist. Ignoring.");
                    } else {
                        console.error("Error fetching data:", error);
                    }
                });
        };

        const datasource = getServerSideDatasource(fetchData);
        gridParams.api.setServerSideDatasource(datasource);
    }, []);
};