import { useState } from "react";
import axios from "axios";

export const Login = (props: any) => {
  const [username, setUsername] = useState("");
  const [emailIsSent, setEmailIsSent] = useState(false);
  const [error, setError] = useState(false);

  const submit = async (e: any) => {
    setError(false);
    setEmailIsSent(true);
    e.preventDefault();
    const user = {
      email: username,
      // password: password,
    };
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/send_login_email/`, user, {
        headers: {
          "Content-Type": "application/json",
        },
        xsrfCookieName: "csrftoken",
        xsrfHeaderName: "X-CSRFToken",
        withCredentials: true,
      })
      .then(function (response: any) {
        if (response.response) setError(true);
      })
      .catch(function (error) {
        setError(true);
      });
  };
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#fafafa",
      }}
    >
      <div
        style={{
          width: "300px",
          borderRadius: "40px",
          padding: "30px",
          margin: "auto",
          marginTop: "200px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <form onSubmit={submit}>
          <div
            style={{
              padding: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/logo.svg"
                alt="Company Logo"
                style={{
                  maxWidth: "25px",
                  maxHeight: "25px",
                  marginRight: "3px",
                  paddingTop: "5px",
                }}
              />
              <span
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#2f4a5b",
                  fontSize: "28px",
                }}
              >
                Deeplife.
              </span>
            </div>
            <div
              style={{
                textAlign: "center",
                fontWeight: "600",
                color: "#2f4a5b",
                fontSize: "14px",
                marginTop: "15px",
                marginBottom: "40px",
                letterSpacing: "1px",
              }}
            >
              OMICS CATALOG
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                fontWeight: "700",
                width: "80%",
                margin: "auto",
                color: "#2f4a5b",
              }}
            >
              {emailIsSent && !error ? (
                <div>
                  <img
                    src="/sms-tracking.svg"
                    alt="Company Logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                  <div style={{ marginTop: "2rem" }}>
                    The email has been sent. Please check your inbox and open the link
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                  <p>Please enter your email address. You will receive a unique link to access the OMICS Catalog. <br />Thank you!</p>
                  </div>
                  <input
                    placeholder="Enter email"
                    name="username"
                    style={{
                      width: "100%",
                      padding: "1rem",
                      marginTop: "2rem",
                      borderRadius: "30px",
                      fontSize: "14px",
                      boxSizing: "border-box",
                      border: "1px solid lightgrey",
                    }}
                    type="text"
                    value={username}
                    required
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div style={{ marginTop: "30px", textAlign: "center" }}>
              {emailIsSent && !error ? (
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#fafafa",
                    color: "#2f4a5b",
                    borderRadius: "30px",
                    padding: "1rem",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "500",
                    margin: "auto",
                    width: "100%",
                  }}
                >
                  I didn't receive the email.
                  <div style={{ textDecoration: "underline", color: "blue" }}>
                    Please resend it.
                  </div>
                </button>
              ) : (
                <button
                  type="submit"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#464DF2",
                    color: "white",
                    borderRadius: "30px",
                    padding: "1rem",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "500",
                    margin: "auto",
                    width: "100%",
                    letterSpacing: "1px",
                  }}
                >
                  SEND ME THE LINK
                </button>
              )}
              {error && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  <p> An error has occured, please contact us at
                  </p>
                </div>
              )}
              <a href="mailto:catalog@deeplife.co"
                    style={{
                      color: "#464DF2",
                      textDecoration: "underline",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}>
                    catalog@deeplife.co
                  </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};